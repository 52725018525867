import * as yup from 'yup';

export const message_rules = yup.object({
    inbound_type: yup.string().required("進線方式尚未選擇"),
    name: yup.string().max(50, "姓名至多為50個字").required('姓名尚未填寫'),
    phone: yup.string()
        .when(
            "inbound_type", 
            (inbound_type: any, schema: any) => {
                if (inbound_type == "0" || inbound_type == "1") {
                    return schema.required("電話尚未填寫");
                }
                return schema;
            }),
    email: yup.string().email('Email格式錯誤'),
    problem_type: yup.string().required('問題類型尚未選擇'),
    problem_subtype: yup.string().required('問題子類型尚未選擇'),
    question: yup.string().max(100, "標題至多為100個字").required('標題尚未填寫'),
    content: yup.string().max(3000, "內容至多為3000個字").required('內容尚未填寫'),
    update_content: yup.string().max(3000, "此次處理事項回覆至多為3000個字").required('此次處理事項回覆尚未填寫'),
    update_status: yup.string().required("更新處理狀態尚未選擇"),
    dispatch_role: yup.string()
        .when(
            "update_status", 
            (update_status: any, schema: any) => {
                if (update_status == "2") {
                    return schema.required("轉派工單位尚未選擇");
                }
                return schema;
            }),
    dispatch_user: yup.string()
        .when(
            "update_status", 
            (update_status: any, schema: any) => {
                if (update_status == "2") {
                    return schema.required("轉派工人員尚未選擇");
                }
                return schema;
            }),
    reply_content: yup.string()
        .when(
            "update_status", 
            (update_status: any, schema: any) => {
                if (update_status == "4") {
                    return schema.max(3000, "回覆民眾事項至多為3000個字").required('回覆民眾事項尚未填寫');
                }
                return schema;
            }),
})

export const message_modify_rules = yup.object({
    inbound_type: yup.string().required("進線方式尚未選擇"),
    name: yup.string().max(50, "姓名至多為50個字").required('姓名尚未填寫'),
    phone: yup.string()
        .when(
            "inbound_type", 
            (inbound_type: any, schema: any) => {
                if (inbound_type == "0" || inbound_type == "1") {
                    return schema.required("電話尚未填寫");
                }
                return schema;
            }),
    // email: yup.string().email('Email格式錯誤'),
    problem_type: yup.string().required('問題類型尚未選擇'),
    problem_subtype: yup.string().required('問題子類型尚未選擇'),
    question: yup.string().max(100, "標題至多為100個字").required('標題尚未填寫'),
    content: yup.string().max(3000, "內容至多為3000個字").required('內容尚未填寫'),
    update_content: yup.string().max(3000, "此次處理事項回覆至多為3000個字").required('此次處理事項回覆尚未填寫'),
    update_status: yup.string().required("更新處理狀態尚未選擇"),
    dispatch_role: yup.string()
        .when(
            "update_status", 
            (update_status: any, schema: any) => {
                if (update_status == "2") {
                    return schema.required("轉派工單位尚未選擇");
                }
                return schema;
            }),
    dispatch_user: yup.string()
        .when(
            "update_status", 
            (update_status: any, schema: any) => {
                if (update_status == "2") {
                    return schema.required("轉派工人員尚未選擇");
                }
                return schema;
            }),
    reply_content: yup.string()
        .when(
            "update_status", 
            (update_status: any, schema: any) => {
                if (update_status == "4") {
                    return schema.max(3000, "回覆民眾事項至多為3000個字").required('回覆民眾事項尚未填寫');
                }
                return schema;
            }),
})