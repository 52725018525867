<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">客服管理</li>
            <li class="breadcrumb-item active" aria-current="page">進線管理</li>
            <li class="breadcrumb-item active" aria-current="page">進線內容</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form col-12 row mx-auto data-form justify-content-start">
                <div class="mb-3 col-md-4">
                    <label for="no_id" class="form-label">序號</label>
                    <input type="hidden" class="form-control" id="message_id" :disabled="true" v-model="message_data.message_id">
                    <input type="text" class="form-control" id="no_id" :disabled="true" v-model="message_data.no_id">
                </div>
                <div class="mb-3 col-md-8">
                    <label for="inbound_type" class="form-label"><span class="required" v-if="$route.params.id === undefined">*</span>進線方式</label>
                    <Field name="inbound_type" v-slot="{ meta, field }" v-model="message_data.inbound_type">
                        <select
                            id="inbound_type"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView' || $route.params.id !== undefined"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <option :value="0">客服專線(中捷)</option>
                            <option :value="1">客服專線(雷門)</option>
                            <option :value="2" :disabled="true">客服專線(臺中通DM)</option>
                            <option :value="3" :disabled="true">車站顧客意見表(中捷)</option>
                            <option :value="4" :disabled="true">車站顧客意見表(雷門)</option>
                            <option :value="5">社群軟體、APP商店評論留言區</option>
                            <option :value="6">其他</option>
                        </select>
                    </Field>
                    <ErrorMessage name="inbound_type" class="invalid-feedback"/>
                </div>
                <div class="mb-3 col-12" v-if="$route.params.id !== undefined">
                    <label for="member_uid" class="form-label">會員編號</label>
                    <input type="text" class="form-control" id="member_uid" :disabled="true" v-model="message_data.member_uid">
                </div>
                <div class="mb-3 col-md-4">
                    <label for="name" class="form-label"><span class="required" v-if="$route.params.id === undefined">*</span>姓名</label>
                    <Field name="name" v-slot="{ meta, field }" v-model="message_data.name">
                        <input
                            id="name"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView' || $route.params.id !== undefined"
                        />
                    </Field>
                    <ErrorMessage name="name" class="invalid-feedback"/>
                </div>
                <div class="mb-3 col-md-4">
                    <label for="phone" class="form-label"><span class="required" v-if="$route.params.id === undefined && (message_data.inbound_type == 0 || message_data.inbound_type == 1) && message_data.inbound_type !== ''">*</span>電話</label>
                    <Field name="phone" v-slot="{ meta, field }" v-model="message_data.phone">
                        <input
                            id="phone"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView' || $route.params.id !== undefined"
                        />
                    </Field>
                    <ErrorMessage name="phone" class="invalid-feedback"/>
                </div>
                <div class="mb-3 col-md-4">
                    <label for="email" class="form-label">Email</label>
                    <Field name="email" v-slot="{ meta, field }" v-model="message_data.email">
                        <input
                            id="email"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView' || $route.params.id !== undefined"
                        />
                    </Field>
                    <ErrorMessage name="email" class="invalid-feedback"/>
                </div>
                <div class="mb-3 col-md-4" v-if="message_data.origin_problem_type !== ''">
                    <label for="origin_problem_type" class="form-label">民眾APP/WEB填寫問題類型</label>
                    <select id="origin_problem_type" class="form-select" v-model="message_data.origin_problem_type" :disabled="true">
                        <option value="" :disabled="true">請選擇</option>
                        <template v-for="item in problem_type_option" :key="item">
                            <option :value="item.value">{{ item.text }}</option>
                        </template>
                    </select>
                </div>
                <div class="mb-3 col-md-4">
                    <label for="problem_type" class="form-label"><span class="required" v-if="$route.name === 'MessageDetailCreate' || ($route.name === 'MessageDetailUpdate' && $route.params.id === undefined) || ($route.name === 'MessageDetailUpdate' && $route.params.id !== undefined && message_data.status === 0)">*</span>問題類型</label>
                    <Field name="problem_type" v-slot="{ meta, field }" v-model="message_data.problem_type">
                        <select
                            id="problem_type"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            @change="onChangeProblemType($event.target.value)"
                            :disabled="$route.name === 'MessageDetailView' || ($route.name === 'MessageDetailUpdate' && message_data.status !== 0)"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in problem_type_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="problem_type" class="invalid-feedback"/>
                </div>
                <div class="mb-3 col-md-4">
                    <label for="problem_subtype" class="form-label"><span class="required" v-if="$route.name === 'MessageDetailCreate' || ($route.name === 'MessageDetailUpdate' && $route.params.id === undefined) || ($route.name === 'MessageDetailUpdate' && $route.params.id !== undefined && message_data.status === 0)">*</span>問題子類型</label>
                    <Field name="problem_subtype" v-slot="{ meta, field }" v-model="message_data.problem_subtype">
                        <select
                            id="problem_subtype"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView' || ($route.name === 'MessageDetailUpdate' && message_data.status !== 0)"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in problem_subtype_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="problem_subtype" class="invalid-feedback"/>
                </div>
                <div class="mb-3 col-md-4">
                    <label for="res_station" class="form-label">反應車站</label>
                    <Field name="res_station" v-slot="{ meta, field }" v-model="message_data.res_station">
                        <input
                            id="res_station"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView' || $route.params.id !== undefined"
                        />
                    </Field>
                    <ErrorMessage name="res_station" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="question" class="form-label"><span class="required" v-if="$route.params.id === undefined">*</span>標題</label>
                    <Field name="question" v-slot="{ meta, field }" v-model="message_data.title">
                        <input
                            id="question"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView' || $route.params.id !== undefined"
                        />
                    </Field>
                    <ErrorMessage name="question" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="content" class="form-label"><span class="required" v-if="$route.params.id === undefined">*</span>諮詢內容</label>
                    <Field name="content" v-slot="{ meta, field }" v-model="message_data.content">
                        <textarea
                            id="content"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView' || $route.params.id !== undefined"
                            rows="8"
                        />
                    </Field>
                    <ErrorMessage name="content" class="invalid-feedback"/>
                </div>
                <div class="mb-3" v-if="message_data.image">
                    <label for="image" class="form-label">圖片</label>
                </div>
                <div class="mb-3" v-if="message_data.image">
                    <img v-if="message_data.image" :src="message_data.image" style="max-width: 50%; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop"/> 
                </div>
                <div class="mb-3" v-if="$route.params.id !== undefined">
                    <label for="status" class="form-label">目前處理狀態</label>
                    <select id="status" class="form-select" :disabled="true" v-model="message_data.status">
                        <option :value="0">未回覆</option>
                        <option :value="1">處理中</option>
                        <option :value="2">轉派</option>
                        <option :value="3">待議件</option>
                        <option :value="4">結案</option>
                    </select>
                </div>
                <div class="mb-3" v-if="$route.params.id !== undefined">
                    <label for="current_user" class="form-label">目前處理人員</label>
                    <input type="text" class="form-control" id="current_user" :disabled="true" v-model="message_data.current_user">
                </div>
                <div class="mb-3">
                    <label for="faq_id" class="form-label">回覆結案FAQ編號</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="faq_id" :disabled="true" v-model="message_data.faq_id">
                        <button class="btn btn-dark" data-bs-toggle="modal" data-bs-target="#staticBackdropSearch" @click.prevent="search_faq_data()" v-if="$route.name !== 'MessageDetailView'">選擇</button>
                        <button class="btn btn-danger" @click.prevent="clear_faq_id()" v-if="$route.name !== 'MessageDetailView'" :disabled="message_data.faq_id === null">清除</button>
                    </div>
                </div>
                <div class="mb-3" v-if="$route.name !== 'MessageDetailView'">
                    <label for="update_content" class="form-label"><span class="required" v-if="$route.name !== 'MessageDetailView'">*</span>此次處理事項回覆</label>
                    <Field name="update_content" v-slot="{ meta, field }" v-model="update_content">
                        <textarea
                            id="update_content"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView'"
                            rows="8"
                        />
                    </Field>
                    <ErrorMessage name="update_content" class="invalid-feedback"/>
                </div>
                <div class="mb-3" v-if="$route.name !== 'MessageDetailView'">
                    <label for="update_status" class="form-label"><span class="required" v-if="$route.name !== 'MessageDetailView'">*</span>更新處理狀態</label>
                    <Field name="update_status" v-slot="{ meta, field }" v-model="update_status">
                        <select
                            id="update_status"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView'"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <option :value="99999" v-if="$route.params.id !== undefined">維持目前處理狀態</option>
                            <option :value="1">處理中</option>
                            <option :value="2">轉派</option>
                            <option :value="3">待議件</option>
                            <option :value="4">結案</option>
                        </select>
                    </Field>
                    <ErrorMessage name="update_status" class="invalid-feedback"/>
                </div>
                <div v-if="update_status == 2">
                    <label class="form-label"><span class="required">*</span>轉派工人員</label>
                </div>
                <div class="mb-3 col-md-6" v-if="update_status == 2">
                    <Field name="dispatch_role" v-slot="{ meta, field }" v-model="dispatch_role">
                        <select
                            id="dispatch_role"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            @change="onChangeRole($event.target.value)"
                            :disabled="$route.name === 'MessageDetailView'"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in role_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="dispatch_role" class="invalid-feedback"/>
                </div>
                <div class="mb-3 col-md-6" v-if="update_status == 2">
                    <Field name="dispatch_user" v-slot="{ meta, field }" v-model="dispatch_user">
                        <select
                            id="dispatch_user"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView'"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in user_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="dispatch_user" class="invalid-feedback"/>
                </div>
                <div class="mb-3" v-if="message_data.status == 4 && $route.params.id !== undefined">
                    <label for="reply_time" class="form-label">回覆時間</label>
                    <input type="text" class="form-control" id="reply_time" v-model="message_data.reply_time" :disabled="true">
                </div>
                <div class="mb-3" v-if="update_status == 4 || ($route.name === 'MessageDetailView' && message_data.status == 4)">
                    <label for="reply_content" class="form-label"><span class="required" v-if="$route.name !== 'MessageDetailView'">*</span>回覆民眾事項</label>
                    <Field name="reply_content" v-slot="{ meta, field }" v-model="message_data.reply_content">
                        <textarea
                            id="reply_content"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'MessageDetailView'"
                            rows="8"
                        />
                    </Field>
                    <ErrorMessage name="reply_content" class="invalid-feedback"/>
                </div>
                <div class="mb-3" v-if="$route.params.id !== undefined">
                    <label class="form-label">處理紀錄</label>
                </div>
                <div class="mb-3 col-12 row mx-auto table-responsive" v-if="$route.params.id !== undefined">
                    <table class="table table-striped table-hover text-center align-middle">
                        <thead class="table-dark">
                            <tr>
                                <th class="col-3">人員</th>
                                <th class="col-3">時間</th>
                                <th class="col-6">事項</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in message_data.message_log" :key="item">
                                <td style="white-space: pre-wrap;">{{ item.user }}</td>
                                <td>{{ item.time }}</td>
                                <td style="white-space: pre-wrap;">{{ item.content }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled" v-if="$route.name !== 'MessageDetailView'">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </div>
        </Form>

        <!-- Search Modal -->
        <div class="modal fade" id="staticBackdropSearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" style="color: #000000;">
                    <div class="modal-header text-center bg-dark text-white">
                        <h5 class="modal-title w-100" id="staticBackdropLabel">
                            選擇FAQ資料
                        </h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" v-on:click="cancel()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12 row mb-3 mx-auto">
                            <div class="col-12 accordion mb-3" id="accordion_filter">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading_filter">
                                        <button class="accordion-button bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_filter" aria-expanded="false" aria-controls="collapse_filter">
                                            搜尋條件
                                        </button>
                                    </h2>
                                    <div id="collapse_filter" class="accordion-collapse show" aria-labelledby="heading_filter" data-bs-parent="#accordion_filter">
                                        <div class="accordion-body col-12 row">
                                            <div class="mb-3 col-lg-4">
                                                <label for="search_faq_id" class="form-label">序號</label>
                                                <input type="text" class="form-control" id="search_faq_id" v-model="display_query_data.faq_id">
                                            </div>
                                            <div class="mb-3 col-lg-4">
                                                <label for="search_problem_type" class="form-label">問題類型</label>
                                                <select id="search_problem_type" class="form-select" v-model="display_query_data.problem_type" @change="onChangeModalProblemType($event.target.value)">
                                                    <option value="">全部</option>
                                                    <template v-for="item in problem_type_option" :key="item">
                                                        <option :value="item.value">{{ item.text }}</option>
                                                    </template>
                                                </select>
                                            </div>
                                            <div class="mb-3 col-lg-4">
                                                <label for="search_problem_subtype" class="form-label">問題子類型</label>
                                                <select id="search_problem_subtype" class="form-select" v-model="display_query_data.problem_subtype">
                                                    <option value="">全部</option>
                                                    <template v-for="item in modal_problem_subtype_option" :key="item">
                                                        <option :value="item.value">{{ item.text }}</option>
                                                    </template>
                                                </select>
                                            </div>
                                            <div class="mb-3 col-lg-4">
                                                <label for="search_question" class="form-label">常見問題</label>
                                                <input type="text" class="form-control" id="search_question" v-model="display_query_data.question">
                                            </div>
                                            <div class="mb-3 col-lg-4">
                                                <label for="search_content" class="form-label">答案</label>
                                                <input type="text" class="form-control" id="search_content" v-model="display_query_data.content">
                                            </div>
                                        </div>
                                        <div class="col-12 row text-end mx-auto mb-3">
                                            <div>
                                                <button class="btn m-2 btn-primary" @click="search_faq_data()">搜尋</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row text-center mx-auto">
                                <div class="table-responsive col-12">
                                    <table class="table table-striped table-hover text-center align-middle">
                                        <thead class="table-dark">
                                            <tr>
                                                <th class="col-1">序號</th>
                                                <th class="col-2">問題類型</th>
                                                <th class="col-2">問題子類型</th>
                                                <th class="col-3">常見問題</th>
                                                <th class="col-3">答案</th>
                                                <th class="col-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in records" :key="item">
                                                <td>{{ item.faq_id }}</td>
                                                <td>{{ item.problem_type }}</td>
                                                <td>{{ item.problem_subtype }}</td>
                                                <td>{{ item.question }}</td>
                                                <td>{{ item.content }}</td>
                                                <td>
                                                    <button type="button" class="btn btn-success" v-on:click="select(item)">選擇</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-12 row mx-auto mb-3">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                                            <a class="page-link" aria-label="<<"
                                                @click.prevent="onChangePage(1)">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                                            <a class="page-link" aria-label="<"
                                                @click.prevent="onChangePage(current_page - 1)">
                                                <span aria-hidden="true">&lsaquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" v-for="page in links" :key="page" 
                                            :class="{'active': page.active, 'disabled': page.label === '...'}" v-bind:style="{'cursor': page.active ? null : page.label === '...' ? null : 'pointer'}">
                                            <a class="page-link" @click.prevent="onChangePage(page.label)">{{ page.label }}</a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                                            <a class="page-link" aria-label=">"
                                                @click.prevent="onChangePage(current_page + 1)">
                                                <span aria-hidden="true">&rsaquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                                            <a class="page-link" aria-label=">>"
                                                @click.prevent="onChangePage(last_page)">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-12 row mx-auto mb-3">
                                <div class="input-group justify-content-center">
                                    <span class="text-vertical-center">前往第</span>
                                    <input type="number" class="form-control m-2" style="flex: 0 0 auto; width: auto;" id="current_page" v-model="current_page" :min="1" :max="9999999999">
                                    <span class="text-vertical-center">頁</span>
                                    <div>  
                                        <button class="btn m-2 btn-primary" @click.prevent="onChangePage(current_page)">前往</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3"></div>
                            <div class="col-12 row mx-auto mb-3" v-if="select_faq_data.faq_id !== '' && select_faq_data.faq_id !== undefined">
                                <div class="table-responsive col-12">
                                    <table class="table table-striped table-hover text-center align-middle">
                                        <thead class="table-dark">
                                            <tr>
                                                <th class="col-6">欄位名稱</th>
                                                <th class="col-6">資訊</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>FAQ編號</td>
                                                <td>{{ select_faq_data.faq_id }}</td>
                                            </tr>
                                            <tr>
                                                <td>問題類型</td>
                                                <td>{{ select_faq_data.problem_type }}</td>
                                            </tr>
                                            <tr>
                                                <td>問題子類型</td>
                                                <td>{{ select_faq_data.problem_type }}</td>
                                            </tr>
                                            <tr>
                                                <td>常見問題</td>
                                                <td>{{ select_faq_data.question }}</td>
                                            </tr>
                                            <tr>
                                                <td>答案</td>
                                                <td>{{ select_faq_data.content }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" v-on:click="confirm()" :disabled="select_faq_data.faq_id === '' || select_faq_data.faq_id === undefined">確認</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" v-on:click="cancel()">關閉</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" style="color: #000000;">
                    <div class="modal-header text-center bg-dark text-white">
                        <h5 class="modal-title w-100" id="staticBackdropLabel">
                            圖片
                        </h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <img v-if="message_data.image" :src="message_data.image" class="mx-auto" style="max-width: 100%; display: block;" data-bs-toggle="modal" data-bs-target="#staticBackdrop"/> 
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">關閉</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    get_message_detail,
    save_message_detail,
    get_select_faq_list,
} from '../../api/message';
import { mapActions, mapGetters } from 'vuex';
import { 
    get_problem_type_option,
    get_role_option,
    get_acccount_by_role_option,
    get_problem_subtype_option,
} from '../../api/data';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { message_rules, message_modify_rules } from '../../rules/customer_service/message';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            problem_type_option: [],
            problem_type_en_option: [],
            message_data: {
                member_uid: "",
                email: "",
                res_staion: "",
                phone: "",
                inbound_type: "",
                problem_type: "",
                title: "",
                content: "",
                status: "",
                message_log: [],
                current_user: "",
                create_user: "",
                reply_content: "",
                reply_time: "",
                problem_subtype: "",
                origin_problem_type: "",
                faq_id: null,
            },
            update_content: "",
            update_status: "",
            dispatch_role: "",
            dispatch_user: "",
            role_option: [],
            user_option: [],
            button_disabled: false,
            can_go_back: false,
            problem_subtype_option: [],
            problem_subtype_en_option: [],
            last_page: 1,
            current_page: 1,
            links: [{
                label: "1",
                active: true,
            }],
            records: [],
            display_query_data: {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                problem_subtype: "",
            },
            query_data: {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                problem_subtype: "",
            },
            select_faq_data: {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                problem_subtype: "",
            },
            modal_problem_subtype_option: [],
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_message_detail({message_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'MessageList' });
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'MessageList' });
                } else {
                    if (this.$route.name === 'MessageDetailUpdate' && response.data.response_data.status === 4) {
                        this.$router.push({ name: 'MessageList' });
                    } else {
                        this.message_data = response.data.response_data;
                        this.onChangeProblemType(response.data.response_data.problem_type, true);
                    }
                }
            })
        },
        save() {
            this.button_disabled = true;
            let submit_message_data = JSON.parse(JSON.stringify(this.message_data));
            submit_message_data.update_content = this.update_content;
            submit_message_data.update_status = this.update_status;
            submit_message_data.dispatch_user = this.dispatch_user;
            save_message_detail(submit_message_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'MessageList' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        onChangeRole(role_id) {
            this.user_option = [];
            this.dispatch_user = "";
            get_acccount_by_role_option({role_id: role_id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.user_option = response.data.response_data;
                }
            })
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "MessageList"});
            }
        },
        onChangeProblemType(problem_type, init = false) {
            if (init === false) {
                this.message_data.problem_subtype = "";
            }
            get_problem_subtype_option({problem_type: problem_type}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.problem_subtype_option = response.data.response_data["TW"];
                    this.problem_subtype_en_option = response.data.response_data["EN"];
                }
            })
        },
        search_faq_data(page = 1, page_change = false) {
            if (page_change === false) {
                this.query_data = JSON.parse(JSON.stringify(this.display_query_data));
            }
            get_select_faq_list({page: page, query_data: this.query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'MessageList' });
                } else {
                    this.records = response.data.response_data.data;
                    this.links = response.data.response_data.links;
                    this.current_page = response.data.response_data.current_page;
                    this.last_page = response.data.response_data.last_page;
                }
            })
        },
        cancel() {
            this.select_faq_data = {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                problem_subtype: "",
            }
            this.display_query_data = {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                problem_subtype: "",
            }
            this.query_data = {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                problem_subtype: "",
            }
        },
        select(item) {
            let faq_data = JSON.parse(JSON.stringify(item));
            this.select_faq_data.faq_id = faq_data.faq_id;
            this.select_faq_data.problem_type = faq_data.problem_type;
            this.select_faq_data.question = faq_data.question;
            this.select_faq_data.content = faq_data.content;
            this.select_faq_data.problem_subtype = faq_data.problem_subtype;
        },
        confirm() {
            let faq_data = JSON.parse(JSON.stringify(this.select_faq_data));
            this.message_data.faq_id = faq_data.faq_id;
            this.message_data.reply_content = faq_data.content;
            this.update_content = "以FAQ做回覆處理 (編號：" + faq_data.faq_id + ")";
            this.cancel();
        },
        onChangeModalProblemType(problem_type) {
            this.query_data.problem_subtype = "";
            get_problem_subtype_option({problem_type: problem_type}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.modal_problem_subtype_option = response.data.response_data["TW"];
                }
            })
        },
        clear_faq_id() {
            this.message_data.faq_id = null;
            this.message_data.reply_content = "";
            this.update_content = "";
        },
        onChangePage(page) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })
            if (page > this.last_page || page <= 0) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，無指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else if (page == this.page) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，已於指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else {
                this.search_faq_data(page, true);
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.search();
        }
        get_problem_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.problem_type_option = response.data.response_data["TW"];
                this.problem_type_en_option = response.data.response_data["EN"];
            }
        })
        get_role_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.role_option = response.data.response_data;
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
        schema() {
            if (this.$route.params.id !== undefined) {
                return message_modify_rules;
            } else {
                return message_rules;
            }
        },
    }
};
</script>
