import request from "../utils/request";

export function get_role_option(data: JSON){
    return request({
        url: "/data/getRoleOption",
        method: "post",
        data: data
    });
}

export function get_town_option(data: JSON){
    return request({
        url: "/data/getTownOption",
        method: "post",
        data: data
    });
}

export function get_business_district_option(data: JSON){
    return request({
        url: "/data/getBusinessDistrictOption",
        method: "post",
        data: data
    });
}

export function get_store_type_option(data: JSON){
    return request({
        url: "/data/getStoreTypeOption",
        method: "post",
        data: data
    });
}

export function get_store_subtype_option(data: JSON){
    return request({
        url: "/data/getStoreSubtypeOption",
        method: "post",
        data: data
    });
}

export function get_problem_type_option(data: JSON){
    return request({
        url: "/data/getProblemTypeOption",
        method: "post",
        data: data
    });
}

export function get_store_all_option(data: JSON){
    return request({
        url: "/data/getStoreAllOption",
        method: "post",
        data: data
    });
}

export function get_acccount_by_role_option(data: JSON){
    return request({
        url: "/data/getAccountByRoleOption",
        method: "post",
        data: data
    });
}

export function get_problem_subtype_option(data: JSON){
    return request({
        url: "/data/getProblemSubtypeOption",
        method: "post",
        data: data
    });
}